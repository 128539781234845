import styles from "./BannerEmail.module.scss"
import React, { useEffect, useState } from "react"
import cx from "clsx"

import Container from "../../layouts/Container/Container"
import Text from "../Text/Text"
import Button from "../Button/Button"
import { fetchUser, userKeys } from "../../atoms/userAtom"
import { fetcher } from "../../utils/fetcher"

import ClientWrapper from "../../utils/ClientWrapper"
import toast from "react-hot-toast"
import { useQuery } from "react-query"
import { useSession } from "next-auth/react"

const BannerEmail = () => {
  const { data: session, status } = useSession()

  const { data: user, status: userStatus } = useQuery(
    userKeys.profile,
    fetchUser,
    {
      enabled: Boolean(session?.token) && Boolean(status === "authenticated"),
    }
  )

  const [loading, setLoading] = useState(false)

  const handleResendVerification = async () => {
    setLoading(true)

    try {
      const res = await fetcher({
        method: "POST",
        url: "/send_verification",
        data: {
          type: "candidate",
        },
        headers: {
          Authorization: `Bearer ${session?.token}`,
        },
      })

      if (res.status === 200) {
        toast.success(
          "Email dikirim! Periksa inbox atau folder spam anda untuk melanjutkan verifikasi email.",
          { duration: 3000 }
        )
      }
    } catch (error) {
      toast.error("Gagal mengirim email! Silakan coba lagi")
      // console.log({ failed_resend: error })
    }

    setLoading(false)
  }

  if (
    status !== "authenticated" ||
    userStatus === "loading" ||
    user?.email_verified
  ) {
    return null
  }

  if (
    status === "authenticated" &&
    userStatus === "success" &&
    !user?.email_verified
  ) {
    return (
      <div className={cx(styles.root)}>
        <Container narrow>
          <Text as="p" heading5 style={{ paddingBottom: 4 }}>
            Email belum diverifikasi
          </Text>
          <Text as="p" small>
            Silakan verifikasi emailmu untuk bisa melamar pekerjaan impianmu!
          </Text>
          <br />
          <footer>
            <Text small>Belum menerima email verifikasi?</Text>{" "}
            <Button
              primaryText
              small
              onClick={handleResendVerification}
              loading={loading}
              disabled={loading}
            >
              Kirim ulang
            </Button>
          </footer>
        </Container>
      </div>
    )
  }

  return null
}

export default ClientWrapper(BannerEmail)
